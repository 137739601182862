.affiliates-container {
  height: 380px;
  background-color: rgb(5, 117, 18);
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 15px;

  padding-left: 5%;
  padding-right: 5%;
  font-weight: 400;
  color: white;

}

@media only screen and (max-width: 730px) {
  .affiliates-container {
    height: 480px;
  }
}


@media only screen and (max-width: 480px) {
  .affiliates-container {
    height: 550px;
  }
}
