.sweepsinfo-container {
  height: 380px;
  background-color: rgb(18, 68, 80);
  display: flex;
  text-align: center;
  padding: 15px;

  
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 400;
  color: white;
}



@media only screen and (max-width: 700px) {
  .sweepsinfo-container {
    height: 450px;
  }
}


@media only screen and (max-width: 480px) {
  .sweepsinfo-container {
    height: 550px;
  }
}

@media only screen and (max-width: 370px) {
  .sweepsinfo-container {
    height: 600px;
  }
}


