.footer-container {
  height: 350px;
  background-color: rgb(6, 27, 54);
  text-align: right;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 30px;

  
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: baseline;
  text-align: left;

  color: white;
}



.footer-left {
  width: 30%;
}

.footer-right {
  width: 22%;
}

@media only screen and (max-width: 1000px) {
  .footer-right {
    width: 28%;
  }
}

@media only screen and (max-width: 800px) {
  .footer-right {
    width: 33%;
  }
}

@media only screen and (max-width: 700px) {
  .footer-container {
    height: 450px;
  }
}

@media only screen and (max-width: 700px) {
  .footer-container {
    height: 520px;
  }
}

@media only screen and (max-width: 680px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    text-align: left;

    height: 850px;
    padding-bottom: 100px;

    }

    .footer-item {
      width: 80%;
    }
}