
.nav-bar {
  background: rgb(24, 24, 24);
  max-height: 80px;
  min-height: 80px;
  min-width: auto;
  display : flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
  justify-content: space-between;

  position: sticky;
  top: 0;
  z-index: 999;
}


#logo-long-header {
  width: 380px;
  position: fixed;
  top: 10px;
  z-index: 100000;
  cursor: pointer;
}

#logo-long-header:hover {
  width: 390px;
  position: fixed;
  top: 10px;
  z-index: 100000;
  cursor: pointer;
}



@media only screen and (max-width: 500px) { 
  #logo-long-header {
    width: 300px;
  }

}

@media only screen and (max-width: 385px) { 
  #logo-long-header {
    width: 250px;
  }

}

@media only screen and (max-width: 320px) { 
  #logo-long-header {
    width: 220px;
  }

}


.right-section {
  font-size: large;
  color: white;
  max-height: 100%;
  min-height: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;

}

.right-section-item {
  margin-left: 20px;
  padding-right: 10px;
  cursor: pointer;
  text-decoration: none;
}


.mobile-menu {
  padding-top: 80px;
  width: 100%;
  height: 100%;
  background-color: rgb(22, 22, 22);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10001;

  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: baseline;
	align-items: stretch;
  text-align: left;
  padding-left: 20px;

  color: white;
  font-size: 2em;
}

.mobile-menu-item:hover {
  color: rgb(190, 190, 190);
}

.right-section-item:hover {
  color: rgb(190, 190, 190);

}

#login-link {
  text-decoration: none !important;
  color: white !important;
}

#login-link:hover {
  color: rgb(190, 190, 190) !important;
}