.font-face-mho {
  font-family: "MyronHectorOutline";
}
.font-face-mhr {
  font-family: "MyronHectorRegular";
}

.maintenance-disclaimer {
  z-index: 100;
  width: 500px;
  height: 200px;
  background-color: rgb(241, 241, 241);
  position: fixed;
  top: 20%;
  left: 33%;
  text-align: center;  
  padding: 20PX;
  border-radius: 5px;
  border: 1px solid grey;
}

@media only screen and (max-width: 900px) { 
  .maintenance-disclaimer {
    left: 5%;
   
  }
}