.body-container {
  height: 700px;
  background-color: rgb(144, 197, 179);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  background-image: url('../../assets/cityscape2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
}

.body-main-title {
  color: white;
  font-size: 6em;
  justify-content: center;
  text-align: center;
  font-weight: 580;


}



@media only screen and (max-width: 900px) {
  .body-container {
    height: 600px;
    background-image: url('../../assets/citiscape2.1.png');
  }
  .body-main-title {
    font-size: 5em;
  }
}

